<template>
  <v-card flat>
    <v-card-text>
      Secondary mapping view
      <base-icon-button
        @click="statusMapping.push({ agencyId })"
      >
        mdi-plus-circle
      </base-icon-button>
      <template v-for="(item, index) in statusMapping">
        <v-row
          :key="`map-${index}`"
        >
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="item.staffStatus"
              label="Staff status"
              :rules="mappingRule"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="item.code"
              label="Code"
              :rules="mappingRule"
            />
          </v-col>
          <v-col cols="3">
            <base-app-text-field
              v-model="item.description"
              label="Description"
              :rules="mappingRule"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-icon-button
              color="ccred"
              @click="deleteItem(index)"
            >
              mdi-minus-circle
            </base-icon-button>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'SecondaryMappingView',
    props: {
      statusMapping: {
        type: Array,
        required: true,
      },
      agencyId: {
        type: Number,
        default: -1,
      },
    },
    data () {
      return {
        mappingRule: [
          v => !!v || 'Value can\'t be empty!',
          v => (v || '').length <= 100 || 'Max 20 characters',
        ],
      }
    },
    computed: {
    },
    methods: {
      deleteItem (index) {
        this.statusMapping.splice(index, 1)
      },
    },
  }
</script>
