<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          cols="6"
        >
          Service Discipline Mapping
          <base-icon-button
            @click="serviceMapping.push({
              agencyId: agencyId,
              service: null,
              serviceDescription: null,
              discipline: null
            })"
          >
            mdi-plus-circle
          </base-icon-button>
        </v-col>
        <v-col
          cols="3"
        >
          <v-btn
            v-if="amsType === 'hhax'"
            color="aluminium"
            class="text-h4"
            :loading="loadingFromAms"
            :disabled="loadingFromAms"
            @click="refreshFromAMS()"
          >
            Refresh from AMS
            <v-icon
              right
              large
              color="primaryTeal"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <base-app-text-field
            v-model="search"
            label="Search"
          />
        </v-col>
      </v-row>
      <template v-for="(item, index) in filteredMapping">
        <v-row
          :key="`map-${index}`"
        >
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="item.service"
              label="Service"
              :rules="mappingRule"
            />
          </v-col>
          <v-col cols="3">
            <base-app-text-field
              v-model="item.serviceDescription"
              label="Service Description"
              :rules="serviceDescriptionRule"
            />
          </v-col>
          <v-col cols="3">
            <base-app-text-field
              v-model="item.discipline"
              label="Discipline"
              :rules="mappingRule"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-icon-button
              color="ccred"
              @click="deleteItem(item)"
            >
              mdi-minus-circle
            </base-icon-button>
            <v-tooltip
              v-if="item.fromAms"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  large
                  color="gray"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-message-alert-outline
                </v-icon>
              </template>
              <span>Mapping pulled from AMS</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  import { eventBus, busEvents } from '@/EventBus'

  export default {
    name: 'ServiceMappingView',
    props: {
      serviceMapping: {
        type: Array,
        default: null,
      },
      agencyId: {
        type: Number,
        default: -1,
      },
      amsType: {
        type: String || null,
        default: null,
      },
    },
    data () {
      return {
        tabs: null,
        search: null,
        mappingRule: [
          v => !!v || 'Value can\'t be empty!',
          v => (v || '').length <= 20 || 'Max 20 characters',
        ],
        serviceDescriptionRule: [
          v => (v || '').length <= 100 || 'Max 100 characters',
        ],
        dataLoaded: false,
        mapping: [],
        loadingFromAms: false,
      }
    },
    computed: {
      filteredMapping () {
        if (this.search) {
          return this.serviceMapping.filter(item => {
            if (item.service && item.discipline) {
              return item.service.toLowerCase().includes(this.search.toLowerCase()) || item.discipline.toLowerCase().includes(this.search.toLowerCase())
            } else {
              return false
            }
          })
        }
        return this.serviceMapping
      },
    },
    watch: {
      serviceMapping (value) {
        if (value) {
          this.dataLoaded = true
        }
        if (this.dataLoaded) {
          console.log('ServiceMapping changed: ', value)
        }
      },
    },
    methods: {
      dataChanged (value) {
        console.log('dataChanged: ', value)
      },
      deleteItem (item) {
        const index = this.serviceMapping.indexOf(this.serviceMapping.find(function (element, index) {
          return element.service === item.service && element.discipline === item.discipline
        }))
        if (index > -1) {
          this.serviceMapping.splice(index, 1)
        }
      },
      async refreshFromAMS () {
        this.loadingFromAms = true
        this.$careConnect.getServiceDisciplineMappingFromAMS(this.agencyId)
          .then(resp => {
            resp.mappings.forEach((mapping) => {
              const exists = !!this.serviceMapping.find((x) =>
                x.service === mapping.service && x.discipline === mapping.discipline)

              if (!exists) {
                this.serviceMapping.push({
                  agencyId: this.agencyId,
                  service: mapping.service,
                  serviceDescription: mapping.serviceDescription,
                  discipline: mapping.discipline,
                  fromAms: true,
                })
              }
            })
          })
          .catch((err) => {
            const text = err.json ? err.json.error : 'Failed to load service discipline mapping from AMS'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.loadingFromAms = false
          })
      },
    },
  }
</script>
