<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          Admins
        </v-col>
        <v-col cols="3">
          <base-app-text-field
            v-model="search"
            label="search"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selectedAdmins"
            checkbox-color="primaryTeal"
            :search="search"
            show-select
            :headers="headers"
            :items="filteredItems"
            disable-pagination
            hide-default-footer
            @input="onInput"
          >
          <!--
            <template v-slot:top>
              <base-app-text-field
                v-model="search"
                label="Search"
              />
            </template>
            -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

  import { mapGetters } from 'vuex'
  export default {
    name: 'AgencyAdminsView',
    props: {
      agencyAdmins: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        selectedAdmins: JSON.parse(JSON.stringify(this.agencyAdmins)),
        search: '',
        headers: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'First name',
            value: 'firstName',
          },
          {
            sortable: true,
            text: 'Last name',
            value: 'lastName',
          },
          {
            sortable: true,
            text: 'Username',
            value: 'username',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        internalAdmins: 'internaladmin/INTERNAL_ADMINS',
      }),
      filteredItems () {
        return this.search ? this.internalAdmins : this.selectedAdmins
      },
    },
    methods: {
      onInput () {
        const { selectedAdmins, agencyAdmins } = this
        const add = selectedAdmins.filter(o => !agencyAdmins.some(i => i.id === o.id)).map(({ id, ...rest }) => id)
        const remove = agencyAdmins.filter(o => !selectedAdmins.some(i => i.id === o.id)).map(({ id, ...rest }) => id)
        const data = add.length > 0 || remove.length > 0 ? { add, remove } : null
        this.$emit('update:admins', data)
      },
    },
  }
</script>
