<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <base-app-dialog-action-button
            color="cloud darken-1"
            @click="$emit('confirm', false)"
          >
            No
          </base-app-dialog-action-button>
          <base-app-dialog-action-button
            @click="$emit('confirm', true)"
          >
            Yes
          </base-app-dialog-action-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: 'AppConfirmationDialog',
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style scoped>

</style>
