<template>
  <v-container
    id="agencies"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="text-right"
      >
        <v-dialog
          v-model="dialog"
          persistent
          width="1200"
          scrollable
          eager
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              depressed
              color="aluminium"
              class="text-h4"
              v-bind="attrs"
              v-on="on"
            >
              Add a new Agency
              <v-icon
                right
                large
                color="primaryTeal"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <base-app-dialog-title
              :title="getTitleText()"
              @close="dialog = false"
            />
            <v-card-text>
              <v-form
                ref="ffa"
                v-model="valid"
              >
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <base-app-text-field
                          v-model="name"
                          label="Agency name"
                          required
                          :rules="uniqueRule"
                        />
                      </v-col>
                      <v-col
                        cols="1"
                      >
                        <v-img
                          v-if="agencyLogoUrl"
                          contain
                          max-width="60"
                          max-height="60"
                          :src="agencyLogoUrl"
                        />
                      </v-col>
                      <v-col cols="5">
                        <dropzone :agency-logo.sync="agencyLogo" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <base-app-text-field
                          v-model="externalId"
                          label="External ID"
                          required
                          :rules="externalIdRule"
                          :disabled="editMode"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-checkbox
                  v-if="localEnv"
                  v-model="demoAgency"
                  label="Demo Agency"
                />
                <!-- TABS -->
                <v-card>
                  <v-tabs
                    v-model="tabs"
                    center-active
                  >
                    <v-tab
                      href="#tab-1"
                    >
                      AMS
                    </v-tab>
                    <template v-if="currentAgency">
                      <v-tab
                        href="#tab-2"
                      >
                        Settings
                      </v-tab>
                      <v-tab
                        href="#tab-3"
                      >
                        Service Discipline Mapping
                      </v-tab>
                      <v-tab
                        href="#tab-4"
                      >
                        Secondary Status Mapping
                      </v-tab>
                      <v-tab
                        href="#tab-5"
                      >
                        Solutions
                      </v-tab>
                      <v-tab
                        href="#tab-6"
                      >
                        Admins
                      </v-tab>
                    </template>
                  </v-tabs>
                  <v-tabs-items v-model="tabs">
                    <!-- AGENCY AMS CONFIGURATION -->
                    <v-tab-item
                      key="1"
                      value="tab-1"
                    >
                      <v-card flat>
                        <v-divider />
                        <v-select
                          v-model="ams"
                          :items="amsIntegrations"
                          label="AMS integration"
                          :disabled="editMode && ams !== null"
                        />
                        <template v-if="ams">
                          <v-divider />
                          <v-col>
                            <v-row v-if="ams === 'sam' || ams === 'hcp' || ams === 'viventium'">
                              <v-col cols="6">
                                <base-app-text-field
                                  v-model="amsUsername"
                                  label="Username"
                                  required
                                  :rules="requiredRule"
                                />
                              </v-col>
                              <v-col cols="6">
                                <base-app-text-field
                                  v-model="amsPassword"
                                  label="Password"
                                  required
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                            <template v-if="ams === 'hhax'">
                              <base-app-text-field
                                v-model="appKey"
                                required
                                :rules="requiredRule"
                                label="App key"
                              />
                              <v-checkbox
                                v-model="writesToHhaxEnabled"
                                :false-value="0"
                                :true-value="1"
                              >
                                <span
                                  slot="label"
                                >Update shifts to AMS</span>
                              </v-checkbox>
                            </template>
                            <template v-if="(ams === 'hhax' || ams === 'viventium')">
                              Caregiver filters
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="primaryTeal"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle
                                  </v-icon>
                                </template>
                                <span>Provide filters as comma delimeted list. e.g. <br>discipline1,discipline2</span>
                              </v-tooltip>
                              <v-row>
                                <v-col cols="6">
                                  <base-app-array-field
                                    v-model="disciplines"
                                    label="Disciplines"
                                    placeholder="discipline1,discipline2"
                                  />
                                </v-col>
                                <v-col
                                  v-if="ams === 'hhax'"
                                  cols="6"
                                >
                                  <base-app-array-field
                                    v-model="staffStatuses"
                                    label="Staff statuses"
                                    placeholder="status1,status2"
                                  />
                                </v-col>
                              </v-row>
                            </template>
                            <template v-if="ams === 'hhax'">
                              Pay rate code mappings
                              <base-icon-button
                                @click="payRateCodeMappings.push({
                                  officeId: null,
                                  discipline: null,
                                  payRateCodeId: null
                                })"
                              >
                                mdi-plus-circle
                              </base-icon-button>
                              <template v-for="(item, index) in payRateCodeMappings">
                                <v-row
                                  :key="`map-${index}`"
                                >
                                  <v-col cols="3">
                                    <base-app-number-field
                                      v-model.number="item.officeId"
                                      label="Office ID"
                                      required
                                      :rules="requiredRule"
                                    />
                                  </v-col>
                                  <v-col cols="3">
                                    <base-app-text-field
                                      v-model="item.discipline"
                                      label="Discipline"
                                      required
                                      :rules="requiredRule"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="3"
                                  >
                                    <base-app-number-field
                                      v-model.number="item.payRateCodeId"
                                      label="payRateCodeId"
                                      required
                                      :rules="requiredRule"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="3"
                                  >
                                    <base-icon-button
                                      color="ccred"
                                      @click="payRateCodeMappings.splice(index, 1)"
                                    >
                                      mdi-minus-circle
                                    </base-icon-button>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                          </v-col>
                        </template>
                      </v-card>
                      <!--
                      <agency-ams-view
                        :ams="ams"
                        :ams-password="amsPassword"
                        :ams-username="amsUsername"
                        :disciplines="disciplines"
                        :staff-statuses="staffStatuses"
                        :pay-rate-code-mappings="payRateCodeMappings"
                        :app-key="appKey"
                        :edit-mode="editMode"
                      />
                      -->
                    </v-tab-item>
                    <!-- AGENCY SETTINGS -->
                    <v-tab-item
                      v-if="currentAgency"
                      key="2"
                      value="tab-2"
                    >
                      <service-settings-view
                        :agency-settings="currentAgency.agencySettings"
                        :case-preferences-defaults="currentAgency.casePreferencesDefaults[0]"
                      />
                    </v-tab-item>
                    <!-- AGENCY SERVICE DISCIPLINE MAPPING -->
                    <v-tab-item
                      v-if="currentAgency"
                      key="3"
                      value="tab-3"
                    >
                      <service-mapping-view
                        :service-mapping="currentAgency.serviceDisciplineMapping"
                        :agency-id="currentAgency.id"
                        :ams-type="ams"
                      />
                    </v-tab-item>
                    <!-- CAREGIVER SECONDARY STATUS -->
                    <v-tab-item
                      v-if="currentAgency"
                      key="4"
                      value="tab-4"
                    >
                      <secondary-mapping-view
                        :status-mapping="currentAgency.caregiverSecondaryStatusCodes"
                        :agency-id="currentAgency.id"
                      />
                    </v-tab-item>
                    <!-- AGENCY SOLUTIONS -->
                    <v-tab-item
                      v-if="currentAgency"
                      key="5"
                      value="tab-5"
                    >
                      <agency-solutions-view
                        :agency-settings="currentAgency.agencySettings"
                        :solutions.sync="currentAgency.solutions"
                        :agency-id="currentAgency.id"
                        :ams-type="ams"
                        :sequence.sync="sequence"
                        :compliance-enabled="complianceEnabled"
                        :vaccine-service-enabled="vaccineServiceEnabled"
                      />
                    </v-tab-item>
                    <!-- AGENCY ADMINS -->
                    <v-tab-item
                      v-if="currentAgency"
                      key="6"
                      value="tab-6"
                    >
                      <agency-admins-view
                        :agency-admins="currentAgency.admins"
                        :admins.sync="adminsReq"
                        :ams-type="ams"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <base-app-dialog-action-button
                v-if="!editMode"
                :disabled="!valid"
                @click="agencyCreateConfirmationDialog = true"
              >
                Create
              </base-app-dialog-action-button>
              <base-app-dialog-action-button
                v-else
                :disabled="!valid"
                @click="agencyUpdateConfirmationDialog = true"
              >
                Update
              </base-app-dialog-action-button>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--
        <v-dialog
          v-model="dialogDisable"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to disable agency?<br>
              This operation cannot be undone<br>
              <span class="text-h4 red--text">{{ disabledItemName }}</span>?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="aluminium"
                @click="closeDisable"
              >
                Cancel
              </v-btn>
              <v-btn
                color="ccred"
                @click="disableItemConfirm"
              >
                Disable
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        -->
      </v-col>
    </v-row>
    <app-confirmation-dialog
      :dialog="agencyCreateConfirmationDialog"
      title="Create agency"
      text="Are you sure you want to create a new agency?"
      @confirm="agencyCreateConfirmed"
    />
    <app-confirmation-dialog
      :dialog="agencyUpdateConfirmationDialog"
      title="Update agency"
      text="Are you sure you want to update agency?"
      @confirm="agencyUpdateConfirmed"
    />
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
              >
                <base-app-text-field
                  v-model="search"
                  label="Search"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="agencies"
              :search="search"
              :items-per-page="25"
              class="cursor-pointer"
              @click:row="showEditDialog"
            >
            <!--
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="primary"

                  @click.stop="disableItem(item)"
                >
                  Disable
                </v-btn>
              </template>
              -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Dropzone from './component/Dropzone.vue'
  import { mapGetters, mapMutations } from 'vuex'
  import ServiceSettingsView from './component/agency/ServiceSettingsView.vue'
  import ServiceMappingView from './component/agency/ServiceMappingView.vue'
  import SecondaryMappingView from './component/agency/SecondaryMappingView.vue'
  import AgencySolutionsView from './component/agency/AgencySolutionsView.vue'
  import AgencyAdminsView from './component/agency/AgencyAdminsView.vue'
  import AppConfirmationDialog from '@/views/dashboard/components/core/AppConfirmationDialog.vue'
  import { eventBus, busEvents } from '@/EventBus'

  export default {
    name: 'AgenciesView',
    components: {
      Dropzone,
      ServiceSettingsView,
      ServiceMappingView,
      AgencySolutionsView,
      SecondaryMappingView,
      AppConfirmationDialog,
      AgencyAdminsView,
      // AgencyAmsView,
    },
    data () {
      return {
        writesToHhaxEnabled: null,
        amsConfig: null,
        vaccineServiceEnabled: false,
        complianceEnabled: false,
        sequence: null,
        adminsReq: null,
        agencyCreateConfirmationDialog: false,
        agencyUpdateConfirmationDialog: false,
        search: null,
        agencyLogo: null,
        agencyLogoUrl: null,
        dragover: false,
        valid: false,
        amsUsername: null,
        demoAgency: false,
        requiredRule: [
          v => !!v || false,
        ],
        uniqueRule: [
          v => !!v || false,
          v => !this.agencies.some(a => a.name === v && (((this.currentAgency && a.id !== this.currentAgency.id)) || !this.currentAgency)) || 'Agency name must be unique',
          v => !this.agencies.some(a => a.agencyExternalId === v && (((this.currentAgency && a.id !== this.currentAgency.id)) || !this.currentAgency)) || 'Agency externalId must be unique',
        ],
        externalIdRule: [
          v => !!v || false,
          v => /^\S*$/.test(v) || 'Whitespace not allowed',
          v => !this.agencies.some(a => a.agencyExternalId === v && (((this.currentAgency && a.id !== this.currentAgency.id)) || !this.currentAgency)) || 'Agency externalId must be unique',
        ],
        amsPassword: null,
        disciplines: [],
        staffStatuses: [],
        payRateCodeMappings: [],
        appKey: null,
        ams: null,
        amsIntegrations: [
          {
            value: null,
            text: 'None',
          },
          {
            value: 'sam',
            text: 'SAM',
          },
          {
            value: 'hcp',
            text: 'HCP',
          },
          {
            value: 'hhax',
            text: 'HHAX',
          },
          {
            value: 'arrow',
            text: 'Arrow',
          },
          {
            value: 'viventium',
            text: 'Viventium',
          },
        ],
        solutions: [],
        name: null,
        externalId: null,
        dialog: false,
        headers: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: true,
            text: 'AMS',
            value: 'amsType',
          },
          /*
          {
            sortable: false,
            value: 'actions',
            align: 'end',
          },
          */
        ],
        editMode: false,
        currentAgency: null,
        tabs: null,
        headersEmployees: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'firstName',
            value: 'firstName',
          },
          {
            sortable: true,
            text: 'lastName',
            value: 'lastName',
          },
          {
            sortable: true,
            text: 'Email',
            value: 'email',
          },
          {
            sortable: true,
            text: 'Dob',
            value: 'dob',
          },
          {
            sortable: true,
            text: 'Discipline',
            value: 'discipline',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Phone',
            value: 'phones',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Status',
            value: 'status',
            align: 'right',
          },
        ],
        localEnv: process.env.NODE_ENV === 'development',
        // dialogDisable: false,
        // disabledItemName: null,
      }
    },
    computed: {
      ...mapGetters({
        agencies: 'agency/AGENCIES',
        isAdmin: 'app/IS_ADMIN',
      }),
      bgColor () {
        return this.editMode ? 'ccgrey lighten-3' : ''
      },
      filteredMapping () {
        if (this.search) {
          return this.currentAgency.serviceDisciplineMapping.filter(item => {
            if (item.service && item.discipline) {
              return item.service.toLowerCase().includes(this.search.toLowerCase()) || item.discipline.toLowerCase().includes(this.search.toLowerCase())
            } else {
              return false
            }
          })
        }
        return this.currentAgency.serviceDisciplineMapping
      },
    },
    watch: {
      dialog (visible) {
        if (visible) {
          this.$refs.ffa.validate()
        } else {
          this.$refs.ffa.resetValidation()
          this.amsUsername = null
          this.amsPassword = null
          this.demoAgency = false
          this.name = null
          this.disciplines = []
          this.staffStatuses = []
          this.appKey = null
          this.ams = null
          this.payRateCodeMappings = []
          this.externalId = null
          this.agencyLogo = null
          this.agencyLogoUrl = null
          this.editMode = false
          this.currentAgency = null
          this.adminsReq = null
          this.sequence = null
          this.complianceEnabled = false
          this.vaccineServiceEnabled = false
          this.amsConfig = null
          this.writesToHhaxEnabled = null
        }
      },
      name (value) {
        setTimeout(() => this.$refs.ffa.validate(), 0)
      },
      ams (value) {
        setTimeout(() => this.$refs.ffa.validate(), 0)
      },
      payRateCodeMappings (value) {
        setTimeout(() => this.$refs.ffa.validate(), 0)
      },
      // externalId (value) {
      // },
      // demoAgency (value) {
      // },
      // amsUsername (value) {
      // },
      // amsPassword (value) {
      // },
      // appKey (value) {
      // },
      // disciplines (value) {
      // },
      // staffStatuses (value) {
      // },
    },
    mounted: function () {
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
        updateAgencies: 'agency/UPDATE_AGENCIES',
        addAgency: 'agency/ADD_AGENCY',
        updateAgency: 'agency/UPDATE_AGENCY',
      }),
      agencyCreateConfirmed (confirmed) {
        this.agencyCreateConfirmationDialog = false
        if (confirmed) this.createAgency()
      },
      agencyUpdateConfirmed (confirmed) {
        this.agencyUpdateConfirmationDialog = false
        if (confirmed) this.editAgency()
      },
      getTitleText () {
        return this.editMode ? 'EDIT AGENCY' : 'ADD NEW AGENCY'
      },
      getButtonTitleText () {
        return this.editMode ? 'Update' : 'Create'
      },
      async loadData () {
        this.setLoading(true)
        this.$careConnect.getAgencies()
          .then(resp => {
            this.updateAgencies(resp.agencies)
          })
          .catch((err) => {
            const text = err.json ? err.json.error : 'Failed to load agencies'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      async createAgency () {
        const {
          agencyLogo,
          name,
          ams,
          externalId,
          demoAgency,
          amsUsername,
          amsPassword,
          appKey,
          disciplines,
          staffStatuses,
          payRateCodeMappings,
        } = this

        const baseConfig = {
          name,
          externalId,
          demoAgency,
          ...(ams && { ams }),
          ...((ams && ams !== 'hhax') && { amsUsername }),
          ...((ams && ams !== 'hhax') && { amsPassword }),
        }
        let amsConfig = null
        if (ams === 'hhax' || ams === 'viventium') {
          amsConfig = {
            ...(ams === 'hhax' && { appKey }),
            ...(disciplines && disciplines.length && { disciplines }),
            ...(staffStatuses && staffStatuses.length && { staffStatuses }),
            ...(payRateCodeMappings && payRateCodeMappings.length && { payRateCodeMappings }),
          }
        }

        const req = {
          ...baseConfig,
          ...(amsConfig && { amsConfig }),
        }

        try {
          if (!this.editMode) {
            // create agency
            const { createdAgency } = await this.$careConnect.createAgency(req)
            this.addAgency(createdAgency.agency)
            if (agencyLogo) {
              // get upload permissions
              const filename = agencyLogo.name
              const contentType = agencyLogo.type
              const { fileUploadUrl } = await this.$careConnect.getFileUploadPermissions(createdAgency.agency.id, { filename, contentType })
              // upload file
              await this.$careConnect.uploadAgencyLogo(fileUploadUrl.uploadUrl, agencyLogo)
              // update agency db
              await this.$careConnect.updateAgencyLogo(createdAgency.agency.id, fileUploadUrl.fileUrl)
            }
            const text = 'Agency created'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
          }
          this.loadData()
        } catch (error) {
          console.log(error)
        } finally {
          this.dialog = false
        }
      },

      async editAgency () {
        const {
          agencyLogo,
          name,
          ams,
          externalId,
          demoAgency,
          amsUsername,
          amsPassword,
          appKey,
          disciplines,
          staffStatuses,
          payRateCodeMappings,
          sequence,
          writesToHhaxEnabled,
        } = this

        const baseConfig = {
          sequence,
          name,
          externalId,
          demoAgency,
          ...(ams && { ams }),
          ...((ams && ams !== 'hhax') && { amsUsername }),
          ...((ams && ams !== 'hhax') && { amsPassword }),
        }
        let amsConfig = null
        if (ams === 'hhax' || ams === 'viventium') {
          amsConfig = {
            ...this.amsConfig,
            ...(ams === 'hhax' && { appKey }),
          }

          if (disciplines.length) amsConfig.disciplines = disciplines
          else delete amsConfig.disciplines

          if (staffStatuses.length) amsConfig.staffStatuses = staffStatuses
          else delete amsConfig.staffStatuses

          if (payRateCodeMappings.length) amsConfig.payRateCodeMappings = payRateCodeMappings
          else delete amsConfig.payRateCodeMappings

          if (writesToHhaxEnabled !== null) amsConfig.writesToHhaxEnabled = writesToHhaxEnabled
        }

        try {
          if (this.editMode) {
            const req = {
              ...baseConfig,
              ...(amsConfig && { amsConfig }),
              agencySettings: this.currentAgency.agencySettings,
              serviceDisciplineMapping: this.currentAgency.serviceDisciplineMapping,
              caregiverSecondaryStatusCodes: this.currentAgency.caregiverSecondaryStatusCodes,
              casePreferencesDefault: this.currentAgency.casePreferencesDefaults[0],
              solutions: this.currentAgency.solutions,
              ...(this.adminsReq && { admins: this.adminsReq }),
            }

            // update agency
            const updatedAgency = await this.$careConnect.updateAgency(this.currentAgency.id, req)
            this.updateAgency(updatedAgency.createdAgency)
            if (agencyLogo) {
              // get upload permissions
              const filename = agencyLogo.name
              const contentType = agencyLogo.type
              const { fileUploadUrl } = await this.$careConnect.getFileUploadPermissions(updatedAgency.createdAgency.id, { filename, contentType })
              // upload file
              await this.$careConnect.uploadAgencyLogo(fileUploadUrl.uploadUrl, agencyLogo)
              // update agency db
              await this.$careConnect.updateAgencyLogo(updatedAgency.createdAgency.id, fileUploadUrl.fileUrl)
            }

            const text = 'Agency updated'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
          }
        } catch (error) {
          console.log(error)
          const text = 'Agency update failed'
          eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
        } finally {
          this.dialog = false
        }
      },

      async showEditDialog (item) {
        this.setLoading(true)
        this.$careConnect.getAgency(item.id)
          .then(agencyDetails => {
            this.currentAgency = agencyDetails.agency
            this.complianceEnabled = this.currentAgency.agencySettings.complianceEnabled
            this.vaccineServiceEnabled = this.currentAgency.solutions.includes('covid')
            this.sequence = this.currentAgency.sequence || null
            const amsConfig = (agencyDetails.agency.agencyManagementSystems) ? agencyDetails.agency.agencyManagementSystems[0] : null
            if (this.currentAgency) {
              this.demoAgency = (this.currentAgency.demoAgency) ? this.currentAgency.demoAgency : false
              this.name = this.currentAgency.name
              this.externalId = '' + this.currentAgency.agencyExternalId
              this.agencyLogoUrl = this.currentAgency.logoUrl
            }
            if (amsConfig) {
              this.ams = amsConfig.amsType
              if (amsConfig.amsType === 'hhax') {
                this.amsConfig = amsConfig.config
                this.appKey = amsConfig.config.appKey
                this.disciplines = amsConfig.config.disciplines || []
                this.staffStatuses = amsConfig.config.staffStatuses || []
                this.payRateCodeMappings = amsConfig.config.payRateCodeMappings || []
                this.writesToHhaxEnabled = amsConfig.config.writesToHhaxEnabled || null
              } else if (amsConfig.amsType === 'viventium') {
                this.amsConfig = amsConfig.config
                this.disciplines = amsConfig.config.disciplines || []
                this.amsUsername = this.currentAgency.amsUsername
                this.amsPassword = this.currentAgency.amsPassword
              } else {
                this.amsUsername = this.currentAgency.amsUsername
                this.amsPassword = this.currentAgency.amsPassword
              }
            } else {
              this.amsUsername = null
              this.amsPassword = null
              this.disciplines = []
              this.staffStatuses = []
              this.appKey = null
              this.ams = null
              this.payRateCodeMappings = []
            }
            this.editMode = true
            this.$refs.ffa.resetValidation()
            this.dialog = true
          })
          .catch((err) => {
            const text = err.json ? err.json.error : 'Failed to load agency details'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      // disableItem (item) {
      //   this.disabledItemName = item.name
      //   this.dialogDisable = true
      // },
      // closeDisable () {
      //   this.disabledItemName = null
      //   this.dialogDisable = false
      // },
      // disableItemConfirm () {
      //   console.log('disable item', this.disabledItemName)
      //   this.dialogDisable = false
      // },
    },
  }
</script>
<style scoped>
.cursor-pointer tbody tr:hover {
  cursor: pointer;
}
</style>
