<template>
  <v-card flat>
    <v-card-text>
      <v-row justify="space-between">
        <v-col cols="12">
          Cost penalties
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.seventhDay"
          >
            <span
              slot="label"
            >seventhDay</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.travelCosts"
          >
            <span
              slot="label"
            >travelCosts</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-number-field
            v-model="agencySettings.splitShift"
            label="splitShift"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.liveInEquivalency"
            label="liveInEquivalency"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-number-field
            v-model="agencySettings.spreadOfHours"
            label="spreadOfHours"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-number-field
            v-model="agencySettings.weeklyOvertime"
            label="weeklyOvertime"
          />
        </v-col>
        <v-col cols="6" />
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          Default case preferences
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-text-field
            v-model="casePreferencesDefaults.preferredHoursStart"
            label="preferredHoursStart"
            :rules="timeRule"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-text-field
            v-model="casePreferencesDefaults.preferredHoursEnd"
            outlined
            label="preferredHoursEnd"
            :rules="timeRule"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <base-app-number-field
            v-model="casePreferencesDefaults.maxTravelTime"
            outlined
            label="maxTravelTime"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <v-select
            v-model="casePreferencesDefaults.transportType"
            :items="transportTypes"
            item-text="title"
            item-value="type"
            label="transportType"
            outlined
            attach
          />
        </v-col>
        <v-col
          cols="12"
        >
          <!--
          <base-app-json-field
            v-model="casePreferencesDefaults.filters"
            label="filters"
          />
          -->
          <v-text-field
            v-model="filters"
            outlined
            label="filters"
            :rules="jsonRule"
          />
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          Shift
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.shiftReminderMode"
            label="ReminderMode"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.shiftReminderMaxAttempts"
            label="ReminderMaxAttempts"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.shiftReminderTime"
            label="ReminderTime"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <v-checkbox
            v-model="agencySettings.shiftReminderAlertEnabled"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >ReminderAlertEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.shiftReminderAlertTiming"
            label="ReminderAlertTiming"
            :disabled="!isAdmin"
          />
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.defaultShiftSearchMode"
            label="defaultShiftSearchMode"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <v-checkbox
            v-model="agencySettings.shiftNotifyPrimaryContactOnly"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >NotifyPrimaryContactOnly</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="2"
        >
          <v-checkbox
            v-model="agencySettings.shiftNonCompliantWarning"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >NonCompliantWarning</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="2"
        />
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          Registration
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.registrationReminderMode"
            label="ReminderMode"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.registrationReminderTime"
            label="ReminderTime"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.registrationReminderMaxAttempts"
            label="ReminderMaxAttempts"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col cols="6" />
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          Covid
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.caregiverCovidSurveyEnabled"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >caregiverCovidSurveyEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.clientCovidSurveyEnabled"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >clientCovidSurveyEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.covidSurveyStartTime"
            label="SurveyStartTime"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col cols="4" />
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          Other
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.editableAttributes"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >editableAttributes</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.defaultSearchAllEmployees"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >defaultSearchAllEmployees</span>
          </v-checkbox>
        </v-col>

        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.medicalRecordIntegration"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >medicalRecordIntegration</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.ssnRequired"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >ssnRequired</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.billingReportOn"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >billingReportOn</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.multiDisciplineEnabled"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >multiDisciplineEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.vaccineServiceEnabled"
            :readonly="!isAdmin"
            :color="bgColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >vaccineServiceEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.virtualTrainingsEnabled"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >virtualTrainingsEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col cols="3" />
      </v-row>
      <v-row justify="space-between">
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.userDualRoleAllowed"
            :readonly="!isAdmin"
            :color="cbColor"
          >
            <span
              slot="label"
              :class="labelColor"
            >userDualRoleAllowed</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="9"
        >
          <base-app-text-field
            v-model="agencySettings.dualRoleDisciplines"
            label="dualRoleDisciplines"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <base-app-text-field
            v-model="agencySettings.invitationAdditionalMessage"
            label="invitationAdditionalMessage"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <base-app-text-field
            v-model="agencySettings.invitationAdditionalMessageEmail"
            label="invitationAdditionalMessageEmail"
            :disabled="!isAdmin"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.hourlyTravelTimePayRate"
            label="hourlyTravelTimePayRate"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.publicTransitPayRate"
            label="publicTransitPayRate"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.milePayRate"
            label="milePayRate"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.minWage"
            label="minWage"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.botUserId"
            label="botUserId"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.hourlyTravelTimePayRate"
            label="hourlyTravelTimePayRate"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.newCaregiverActivationFee"
            label="newCaregiverActivationFee"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.schedulingSystemIntegrationName"
            label="schedulingSystemIntegrationName"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-text-field
            v-model="agencySettings.timezone"
            label="timezone"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.annualTrainingAmount"
            label="annualTrainingAmount"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.smcSupport"
            label="smcSupport"
            :disabled="!isAdmin"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <base-app-number-field
            v-model="agencySettings.weekStartDay"
            label="weekStartDay"
            :disabled="!isAdmin"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { TransportTypes } from '@/enums'
  export default {
    name: 'ServiceSettingsView',
    props: {
      agencySettings: {
        type: Object,
        default: null,
      },
      casePreferencesDefaults: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        transportTypes: TransportTypes,
        nonEmptyRule: [
          v => !!v || false,
        ],
        jsonRule: [
          v => {
            try {
              return !!JSON.parse(v || '{}')
            } catch (error) {
              return 'Not valid JSON'
            }
          },
        ],
        timeRule: [
          v => (!v || /(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(v)) || 'Valid time format is HH:MM:SS',
        ],
      }
    },
    computed: {
      ...mapGetters({
        isAdmin: 'app/IS_ADMIN',
      }),
      filters: {
        set (val) {
          if (!val) {
            this.casePreferencesDefaults.filters = null
          } else {
            try {
              this.casePreferencesDefaults.filters = JSON.parse(val)
            } catch (error) {
            }
          }
        },
        get () {
          return this.casePreferencesDefaults.filters
            ? JSON.stringify(this.casePreferencesDefaults.filters)
            : ''
        },

      },
      bgColor () {
        return this.isAdmin ? '' : 'ccgrey lighten-3'
      },
      labelColor () {
        return this.isAdmin ? 'black--text' : 'ccgrey--text'
      },
      cbColor () {
        return this.isAdmin ? '' : 'ccgrey lighten-3'
      },
    },
  }
</script>
