export const GenderTypes = [
  { title: 'Male', gender: 'm' },
  { title: 'Female', gender: 'f' },
  { title: 'Other', gender: 'u' },
]

export const PhoneTypes = [
  { title: 'home', type: 'home' },
  { title: 'work', type: 'work' },
  { title: 'mobile', type: 'mobile' },
]

export const RelationTypes = [
  { title: 'Parent', relation: 'parent' },
  { title: 'Sibling', relation: 'sibling' },
  { title: 'Child', relation: 'child' },
  { title: 'Spouse', relation: 'spouse' },
  { title: 'Other', relation: 'other' },
]

export const StatusTypes = [
  { title: 'Inactive', status: 'inactive' },
  { title: 'Pending', status: 'pending' },
  { title: 'Active', status: 'active' },
]

export const StaffStatusTypes = [
  { title: 'Pending', staffStatus: 'pending' },
  { title: 'Active', staffStatus: 'active' },
  { title: 'Hold', staffStatus: 'hold' },
  { title: 'Terminated', staffStatus: 'terminated' },
  { title: 'Cancelled', staffStatus: 'cancelled' },
]

export const TransportTypes = [
  { title: 'public', type: 'public' },
  { title: 'walking', type: 'walking' },
  { title: 'driving', type: 'driving' },
]
