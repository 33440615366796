<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          E-learning
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="checkboxValues"
            value="elearn"
          >
            <span
              slot="label"
              class="black--text"
            >Enabled</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          Compliance
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="checkboxValues"
            value="compliance"
            :disabled="complianceEnabled"
            @change="toggleCompliance"
          >
            <span
              slot="label"
              :class="complianceEnabled ? 'ccgrey--text' : 'black--text'"
            >Enabled</span>
          </v-checkbox>
        </v-col>
      </v-row>
      <template v-if="amsType === 'sam'">
        <v-row justify="space-between">
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingClientId"
              label="TrainingClientId"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingAdmissionType"
              label="TrainingAdmissionType"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingEventCode"
              label="TrainingEventCode"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingCompany"
              label="TrainingCompany"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingService"
              label="TrainingService"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-text-field
              v-model="agencySettings.complianceTrainingCancellationreasoncode"
              label="TrainingCancellationreasoncode"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <base-app-array-field
              v-model="agencySettings.exclusionTypes"
              label="Exclusion types"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-checkbox
              v-model="agencySettings.shiftNonCompliantWarning"
              :disabled="!solutions.includes('compliance')"
            >
              <span
                slot="label"
                class="black--text"
                :class="!solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
              >Shift non compliant warning</span>
            </v-checkbox>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="space-between">
          <v-col
            cols="3"
          >
            <base-app-array-field
              v-model="agencySettings.exclusionTypes"
              label="Exclusion types"
              :disabled="!solutions.includes('compliance')"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-checkbox
              v-model="agencySettings.shiftNonCompliantWarning"
              :disabled="!solutions.includes('compliance')"
            >
              <span
                slot="label"
                class="black--text"
                :class="!solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
              >Shift non compliant warning</span>
            </v-checkbox>
          </v-col>
          <v-col cols="6" />
        </v-row>
      </template>
      <v-row justify="start">
        <!--
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.vaccineServiceEnabled"
            :disabled="!isAdmin || vaccineServiceEnabled || !solutions.includes('compliance')"
          >
            <span
              slot="label"
              class="black--text"
              :class="!isAdmin || vaccineServiceEnabled || !solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
            >vaccineServiceEnabled</span>
          </v-checkbox>
        </v-col>
        -->
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="agencySettings.manualExternalIdEnabled"
            :disabled="!solutions.includes('compliance')"
          >
            <span
              slot="label"
              :class="!solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
            >manualExternalIdEnabled</span>
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <base-app-number-field
            :value="sequence"
            label="Sequence current_value"
            :disabled="agencySettings.manualExternalIdEnabled || !solutions.includes('compliance')"
            @change="$emit('update:sequence', parseInt($event, 10))"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span :class="!solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'">
            Compliance features
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="checkboxValues"
            value="covid"
            :disabled="vaccineServiceEnabled || !solutions.includes('compliance')"
            @change="toggleCovid"
          >
            <span
              slot="label"
              :class="vaccineServiceEnabled || !solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
            >Coronavirus Vaccine service</span>
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
        >
          <v-checkbox
            v-model="checkboxValues"
            value="reports"
            :disabled="!solutions.includes('compliance')"
          >
            <span
              slot="label"
              class="black--text"
              :class="!solutions.includes('compliance') ? 'ccgrey--text' : 'black--text'"
            >Compliance embed reports</span>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'AgencySolutionsView',
    props: {
      agencySettings: {
        type: Object,
        default: null,
      },
      solutions: {
        type: Array,
        default: () => ([]),
      },
      agencyId: {
        type: Number,
        default: -1,
      },
      amsType: {
        type: String || null,
        default: null,
      },
      sequence: {
        type: Number,
        default: 0,
      },
      complianceEnabled: {
        type: Boolean,
        default: false,
      },
      vaccineServiceEnabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        checkboxValues: this.solutions,
        nonEmptyRule: [
          v => !!v || false,
        ],
      }
    },
    computed: {
      ...mapGetters({
        isAdmin: 'app/IS_ADMIN',
      }),
    },
    watch: {
      checkboxValues (val) {
        this.$emit('update:solutions', val)
      },
    },
    methods: {
      toggleCompliance (data) {
        const enabled = data.some(e => e === 'compliance')
        // Set agency_settings value
        this.agencySettings.complianceEnabled = enabled
        if (enabled) {
          this.agencySettings.exclusionTypes = ['oig', 'medicaid', 'sam']
          this.agencySettings.shiftNonCompliantWarning = true
          if (this.amsType === 'sam') {
            this.agencySettings.complianceTrainingClientId = '199999911'
            this.agencySettings.complianceTrainingEventCode = 'CCINS'
            this.agencySettings.complianceTrainingService = 'CCINS'
            this.agencySettings.complianceTrainingCancellationreasoncode = 'CCINS'
            this.agencySettings.complianceTrainingCompany = '01'
            this.agencySettings.complianceTrainingAdmissionType = null
            this.agencySettings.schedulingSystemIntegrationName = 'sam'
          }
        } else {
          this.agencySettings.exclusionTypes = null
          this.agencySettings.complianceEnabled = false
          this.agencySettings.complianceTrainingClientId = null
          this.agencySettings.complianceTrainingAdmissionType = null
          this.agencySettings.complianceTrainingEventCode = null
          this.agencySettings.complianceTrainingService = null
          this.agencySettings.complianceTrainingCancellationreasoncode = null
          this.agencySettings.complianceTrainingCompany = null
          this.agencySettings.shiftNonCompliantWarning = false

          // Remove vaccine service when compliance removed
          const idx = this.checkboxValues.findIndex(e => e === 'covid')
          if (idx >= 0) {
            this.checkboxValues.splice(idx, 1)
            this.agencySettings.vaccineServiceEnabled = false
          }
        }
      },
      toggleCovid (data) {
        const enabled = data.some(e => e === 'covid')
        // Set agency_settings value
        this.agencySettings.vaccineServiceEnabled = enabled
      },
    },
  }
</script>
