<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <input
        id="inputEl"
        ref="fileInput"
        type="file"
        accept=".png,.jpg,.jpeg"
        style="display:none"
        @change="onChange"
        @click="$refs.fileInput.value=null"
      >
      <v-sheet
        ref="uploadSheet"
        tabindex="0"
        width="100%"
        height="100%"
        :class="['upload-sheet', imageUrl ? 'no-hover-effect' : '']"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDragDrop"
        @dragover="onDragOver"
        @click="onSheetClick"
      >
        <div :class="['logo-container', imageUrl ? 'no-hover-effect' : '']">
          <template v-if="!imageUrl">
            <div class="logo-content">
              <v-container class="fill-height">
                <v-row justify="space-around">
                  <v-icon
                    v-if="!dragover"
                    color="primaryTeal"
                    size="75"
                  >
                    mdi-cloud-upload-outline
                  </v-icon>
                  <v-icon
                    v-if="dragover"
                    color="primaryTeal"
                    size="75"
                  >
                    mdi-file-plus
                  </v-icon>
                </v-row>
                <v-row justify="center">
                  <span class="text-h5">
                    Agency logo
                  </span>
                </v-row>
              </v-container>
            </div>
          </template>
          <template v-else>
            <div class="logo-content">
              <v-img
                contain
                max-height="100%"
                width="100%"
                :src="imageUrl"
              />
              <v-icon
                v-if="imageUrl"
                class="logo-remove-icon"
                large
                @click="removeFile"
              >
                mdi-close
              </v-icon>
            </div>
          </template>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  const ALLOWED_FILE_TYPES = [
    'image/png',
    'image/jpeg',
  ]
  export default {
    name: 'MyDropzone',
    props: {
      agencyLogo: {
        type: File,
        required: false,
        default: null,
      },
    },
    data: () => ({
      dragover: false,
    }),
    computed: {
      imageUrl () {
        return this.agencyLogo ? URL.createObjectURL(this.agencyLogo) : null
      },
    },
    mounted () {
    },
    methods: {
      removeFile (e) {
        e.preventDefault()
        e.stopPropagation()
        this.logoSelected(null)
      },
      onChange (e) {
        e.preventDefault()
        this.logoSelected(e.target.files[0])
      },
      onSheetClick (e) {
        // Trigger file input
        document.getElementById('inputEl').click()
      },
      onDragEnter (e) {
        e.preventDefault()
        this.dragover = true
      },
      onDragLeave (e) {
        e.preventDefault()
        this.dragover = false
      },
      onDragOver (e) {
        e.preventDefault()
        this.dragover = true
      },
      onDragDrop (e) {
        e.preventDefault()
        this.dragover = false
        const allowedFileType = ALLOWED_FILE_TYPES.some(a => a === e.dataTransfer.files[0].type)
        if (e.dataTransfer && allowedFileType) {
          this.logoSelected(e.dataTransfer.files[0])
        }
      },
      logoSelected (file) {
        this.$emit('update:agency-logo', file)
      },

    },
  }
</script>

<style scoped>
.dropzone-over {
  border: 2px dashed #000;
  cursor: copy
}

.my-container {
cursor: pointer;
height: 100%;
}

.my-container:hover {
  border: 2px dashed #000;
}

.remove-image {
  position: relative;
  top: 0;
  right: 0;
}

.logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--v-aluminium--base);
  /*
  border: 2px solid var(--v-aluminium--base);
  */
  border: 2px dashed #000;
  cursor: pointer;
}

.logo-container:not(.no-hover-effect):hover {
  border: 2px dashed #000;
}

.logo-container.no-hover-effect {
  border: 2px solid var(--v-aluminium--base);
}

.logo-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}

.logo-remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.upload-sheet {
  background-color: var(--v-aluminium-base);
}

.upload-sheet:not(.no-hover-effect) {
  background-color: #fff;
}
</style>
